import React from 'react';
import { Link } from 'gatsby';

export default ({ allPages, allAnalysts, allCategories, allProducts, allPosts, site, options }) => (
    <>
        <div style={{margin: '4rem auto' }}>
            <h1>All Pages</h1>
            <ul style={{ padding: 0 }}>
                {allPages.map(page => (
                    <li
                        key={page.id}
                        style={{
                            textAlign: 'center',
                            listStyle: 'none',
                        }}
                    >
                        <Link to={`/page/${page.postName}`}>
                            <p>{page.postTitle}</p>
                        </Link>
                    </li>
                ))}
            </ul>
            <h1>All Analysts</h1>
            <ul style={{ padding: 0 }}>
                {allAnalysts.map(analyst => (
                    <li
                        key={analyst.id}
                        style={{
                            textAlign: 'center',
                            listStyle: 'none',
                        }}
                    >
                        <Link to={`/analyst/${analyst.postName}`}>
                            <p>{analyst.postTitle}</p>
                        </Link>
                    </li>
                ))}
            </ul>
            <hr />
            <h1>All Products</h1>
            <ul style={{ padding: 0 }}>
                {allProducts.map(product => (
                    <li
                        key={product.id}
                        style={{
                            textAlign: 'center',
                            listStyle: 'none',
                        }}
                    >
                        <Link to={product.permalink}>
                            <p>{product.name}</p>
                        </Link>
                    </li>
                ))}
            </ul>
            <h1>All Categories</h1>
            <ul style={{ padding: 0 }}>
                {allCategories.map(category => (
                    <li
                        key={category.id}
                        style={{
                            textAlign: 'center',
                            listStyle: 'none',
                        }}
                    >
                        <Link to={`/category/${category.slug}`}>
                            <p>{category.name}</p>
                        </Link>
                    </li>
                ))}
            </ul>
            <h1>All Posts</h1>
            <ul style={{ padding: 0 }}>
                {allPosts.map(post => (
                    <li
                        key={post.id}
                        style={{
                            textAlign: 'center',
                            listStyle: 'none',
                        }}
                    >
                        <Link to={`/post/${post.postName}`}>
                            <p>{post.postTitle}</p>
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    </>
);
